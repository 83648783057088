@font-face {
  font-family: "EF";
  src: url("EffraLight-Regular.woff2") format("woff2"), url("EffraLight-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "EF";
  src: url("Effra-Regular.woff2") format("woff2"), url("Effra-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "EF";
  src: url("EffraMedium-Regular.woff2") format("woff2"), url("EffraMedium-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "ME";
  src: url("MonumentExtended-Ultralight.woff2") format("woff2"), url("MonumentExtended-Ultralight.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
